import React from "react"
import styles from "./index.module.css"
import Header from "../components/header"
import {
  BUILD_IEXMH,
  BUILD_TYPO_2,
  PERIFERIA,
} from "../components/images"
import Footer from "../components/footer"
import TimeLine from "../components/timeline"
import AbstractGraFID from "../components/graphid"
import Hero from "../components/hero"
import Featured from "../components/featured"

export default () => (
  <div>
    <Header/>
    <div>
      <Hero/>
      <Featured/>
      <section className="large-pad" style={{ padding: "76px 0" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-10 col-md-offset-1 col-sm-12 text-center">
              <h5 style={{ fontWeight: 900 }}>
                ΕΠΙΧΕΙΡΗΣΙΑΚΟ ΠΡΟΓΡΑΜΜΑ ΔΥΤΙΚΗ ΕΛΛΑΔΑ 2014-2020
              </h5>
              <p style={{ fontSize: "1.5rem" }}>
                Ενίσχυση Μικρομεσαίων Επιχειρήσεων για ερευνητικά έργα στους τομείς «Μικροηλεκτρονική»
                (περιλαμβανομένων των εφαρμογών ενσωματωμένων συστημάτων και
                καινοτόμων εφαρμογών πληροφορικής) και των «προηγμένων υλικών»

                <img {...PERIFERIA} style={{ marginTop: "4rem" }}/>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="typo" className="image-block short-pad">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4">
              <img className="background-image" {...BUILD_TYPO_2}/>
            </div>
            <div className="col-md-8">
              <div className="detail-line"/>
              <h4>Δ. ΠΑΝΑΓΙΩΤΟΠΟΥΛΟΣ & ΣΙΑ (Tipografio.gr)</h4>

              {/*<div className="container">*/}
              {/*<div className="row">*/}
              <div className="col-md-6">
                <p>
                  Η τέχνη συναντά την τεχνολογία και μαζί δημιουργούν σύγχρονα προϊόντα εκτύπωσης
                  υψηλής αισθητικής και άρτιας ποιότητας. Πιστεύουμε στις ιδέες σας. Για αυτό και
                  εξασφαλίζουμε τις καλύτερες τεχνικές και μεθόδους για κάθε εργασία έτσι ώστε
                  κάθε project να φτάσει το 100% των προοπτικών του και να κάνει το όραμά κάθε
                  δημιουργού πραγματικότητα.
                </p>
                <p>
                  Το attention to detail, η επιμονή στη λεπτομέρεια και το μεράκι των ανθρώπων
                  Του Tipografio.gr εγγυώνται το καλύτερο δυνατό αποτέλεσμα για κάθε εργασία.
                  Επενδύουμε
                  συνεχώς στη σχέση μεταξύ δημιουργού και τυπογράφου, επιδιώκοντας μια αμφί-
                  δρομη σχέση εμπιστοσύνης αλλά και δημιουργικότητας. Το Tipografio.gr είναι
                  εγγυητής ποιότητας και creative workshop, με βασικό στόχο την εξύψωση κάθε
                  γραφιστικής δημιουρ-
                  γίας στο καλύτερο δυνατό αποτέλεσμα, δημιουργώντας προστιθέμενη αξία σε κάθε
                  project και κάθε brand.
                </p>
                <p>
                  H εταιρεία προσανατολισμένη στην καινοτομία, με στόχο την υψηλή
                  ανταγωνιστικότητα, αλλά και τις καλύτερες υπηρεσίες προς τους πελάτες της,
                  επενδύει συνεχώς στην έρευνα και την ανάπτυξη
                </p>
              </div>
              <div className="col-md-6">
                <div className="wide-feature">
                  <h3 style={{ fontWeight: 900 }}>
                    Μέθοδοι εκτύπωσης
                  </h3>
                  <ul>
                    <li>Λιθογραφία offset</li>
                    <li>Digital printing</li>
                    <li>Παραδοσιακή τυπογραφία (letterpress)</li>
                    <li>Μεταξοτυπία</li>
                  </ul>
                </div>
                <div className="wide-feature">
                  <h3 style={{ fontWeight: 900 }}>
                    Quality Control
                  </h3>
                  <ul>
                    <li>Media standard print</li>
                    <li>FograCert quality assurance for prepress, proof, CtP and press
                      installation
                    </li>
                    <li>Calibration, profiling and proof output in compliance with the Fogra
                    </li>
                    <li>Contract Proof Creation</li>
                  </ul>
                </div>
                {/*</div>*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
        </div>

      </section>

      <section id="iexmh" className="image-block short-pad">
        <div className="container-fluid">
          {/*<div className="container-fluid">*/}
          <div className="detail-line"/>
          <h4>Ινστιτούτο Επιστημών Χημικής Μηχανικής (ΙΕΧΜΗ)</h4>
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-6">
                <p>
                  Το Ινστιτούτο Επιστημών Χημικής Μηχανικής (ΙΕΧΜΗ) ιδρύθηκε στην Πάτρα το 1984
                  και άρχισε να λειτουργεί ως Ανεξάρτητο Ακαδημαϊκό Ινστιτούτο (αρχικά υπό την
                  ονομασία Ερευνητικό Ινστιτούτο Χημικής Μηχανικής και Χημικών Διεργασιών Υψηλής
                  Θερμοκρασίας - ΕΙΧΗΜΥΘ). Το 1987 το ΙΕΧΜΗ υπήρξε ένα από τα ιδρυτικά Ινστιτούτα
                  τα οποία συνενώθηκαν για να σχηματίσουν το ΙΤΕ (Ίδρυμα Τεχνολογίας και Έρευνας).
                  Το ΙΤΕ περιλαμβάνει έξι Ερευνητικά Ινστιτούτα (4 στο Ηράκλειο, 1 στο Ρέθυμνο,
                  και 1 στην Πάτρα) και σε όλες τις περιοδικές αξιολογήσεις έχει καταταγεί ως «το
                  πρώτο Ερευνητικό Κέντρο που εκτείνεται σε όλη την Ελλάδα". Το ΙΤΕ υπάγεται στη
                  Γενική Γραμματεία Έρευνας και Τεχνολογίας (ΓΓΕΤ) του Υπουργείου Παιδείας,
                  Έρευνας και Θρησκευμάτων. Τα Ερευνητικά Ινστιτούτα του ΙΤΕ συνεργάζονται στενά
                  και δημιουργικά, ως ένα ολοκληρωμένο δίκτυο έρευνας και τεχνολογικής ανάπτυξης.
                </p>
                <p>
                  Έχει δεκαετή εμπειρία και ενασχόληση με το γραφένιο και την τεχνολογία του.
                  Συνεργάζεται με μεγάλα κέντρα του εξωτερικού (Univ. Of Cambridge, Manchester,
                  CNR Italy) και συμμετέχει στο εμβληματικό εργαλείο της ΕΕ για την ενίσχυση της
                  έρευνας και της επιχειρηματικότητα, που ονομάζεται Graphene Flagship.
                  Συνεργάζεται από το 2009 με τους κατόχους του βραβείου Nobel Φυσικής 2010 για το
                  γραφένιο, κυρίως για τον χαρακτηρισμό των μηχανικών ιδιοτήτων του γραφενίου με
                  φασματοσκοπικές μεθόδους.
                </p>
              </div>
              <div className="col-md-6">
                <div className="wide-feature">
                  <h3 style={{ fontWeight: 900 }}>
                    Αποστολή του ΙΤΕ/ΙΕΧΜΗ
                  </h3>
                  <ul>
                    <li>Διεξαγωγή επιστημονικής έρευνας αιχμής.</li>
                    <li>Ανάπτυξη και καλλιέργεια συνεργασιών με βιομηχανικές επιχειρήσεις στις
                      χώρες της
                    </li>
                    <li>ΕΕ καθώς και σε άλλες.</li>
                    <li>Ανάπτυξη καινοτόμων τεχνολογικών προϊόντων, ευφυών εξειδικευμένων
                      τεχνολογιών
                    </li>
                    <li>και διεργασιών παραγωγής.</li>
                    <li>Συμμετοχή στη δημιουργία εταιρειών-τεχνοβλαστών.</li>
                    <li>Συνεργασία με άλλους ερευνητικούς οργανισμούς.</li>
                    <li>Συμμετοχή στην εκπαίδευση και επιμόρφωση νέων ερευνητών.</li>
                    <li>Έντονη συμβολή στην ανάπτυξη της Περιφέρειας Δυτικής Ελλάδας.</li>
                    <li>Σημαντική συμβολή στην τεχνολογική και οικονομική ανάπτυξη της χώρας.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className={styles.cimage}>
              <img {...BUILD_IEXMH}/>
            </div>
            {/*</div>*/}
          </div>
        </div>

      </section>

      <TimeLine/>

      <div id="graph">
        <AbstractGraFID/>
      </div>

    </div>
    <Footer/>
  </div>
);

