import React from "react"
import styles from "../pages/index.module.css"
import { GAZA_1 } from "./images"

const TimeLine = () => {
  return (
    <section className="timeline-1">
      <div className="background-image-holder overlay" style={{ opacity: 1 }}>
        <img className={styles.slideImage} {...GAZA_1}/>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <div className="timeline-section">
              <div className="left-content timeline-content">
                <h2 className="text-white">Ιδέα&nbsp;</h2>
                <p className="text-white">
                  Η ύπαρξη πλαστών φαρμάκων στην αγορά και η επίδραση τους στη δημόσια υγεία, και
                  στην οικονομία, σε συνδυασμό με την ευρωπαϊκή νομοθεσία, μας έδωσαν την ιδέα.
                </p>
              </div>

              <div className="right-content timeline-content">
                <i className="text-white fa fa-lightbulb"/>
              </div>
            </div>
            <div className="timeline-section">
              <div className="left-content timeline-content">
                <i className="text-white fas fa-hand-holding-heart"/>
              </div>
              <div className="right-content timeline-content">
                <h2 className="text-white">Συνεργασία</h2>
                <p className="text-white">
                  Η ενασχόληση του Tipografio.gr με το NFC και η ενασχόληση του ΙΤΕ/ΙΕΧΜΗ με το
                  νέο και πολλά υποσχόμενο αγώγιμο υλικό, το γραφένιο δημιούργησαν τη συνεργασία.
                </p>
              </div>
            </div>
            <div className="timeline-section">
              <div className="left-content timeline-content">
                <h2 className="text-white">&nbsp;Χρηματοδότηση</h2>
                <p className="text-white">
                  Η δράση “Ενίσχυση Μικρομεσαίων Επιχειρήσεων για ερευνητικά έργα στους τομείς
                  «Μικροηλεκτρονική» (περιλαμβανομένων των εφαρμογών ενσωματωμένων συστημάτων και
                  καινοτόμων εφαρμογών πληροφορικής) και των «προηγμένων υλικών»” του
                  επιχειρησιακού προγράμματος Δυτική Ελλάδα 2014-2020 έφερε την χρηματοδότηση.
                </p>
              </div>

              <div className="right-content timeline-content">
                <i className="fa fa-hands-helping text-white"/>
              </div>
            </div>

            <div className="timeline-section">
              <div className="left-content timeline-content">
                <i className="text-white fa fa-wrench"/>
              </div>

              <div className="right-content timeline-content">
                <h3 className="text-white">Υλοποίηση</h3>
                <p className="text-white">
                  Τον Φεβρουάριο του 2018 ξεκίνησε η υλοποίηση του προγράμματος “Εκτύπωση και
                  παραγωγή κυκλωμάτων τεχνολογίας ανταλλαγής δεδομένων από κοντινή απόσταση (NFC)
                  με μελάνια γραφενίου για έξυπνες συσκευασίες φαρμακευτικών σκευασμάτων” και
                  αναμένεται να έχει θετικά αποτελέσματα για όλους τους εμπλεκόμενους και όχι
                  μόνο.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}


export default TimeLine