import React from 'react';
import { LOGO_GRAFID, LOGO_IEXMH, LOGO_TYPO } from "./images"
import styles from './featured.module.css';

const Featured = () =>  {
        return (
          <section className="image-features">
            <div className="container">
              <div className="row">
                <div className="col-md-4 col-sm-12">
                  <div className={"image-feature " + styles.featured}>
                    <div>
                      <img alt="Feature Image" {...LOGO_TYPO}/>
                    </div>
                    <h5>Δ. ΠΑΝΑΓΙΩΤΟΠΟΥΛΟΣ & ΣΙΑ</h5>
                    <p>
                      Η τέχνη συναντά την τεχνολογία και μαζί δημιουργούν σύγχρονα προϊόντα εκτύπωσης
                      υψηλής αισθητικής και άρτιας ποιότητας.
                    </p>
                    <a href="#typo">Περισσότερα</a>
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className={"image-feature " + styles.featured}>
                    <div>
                      <img alt="Feature Image" {...LOGO_IEXMH}/>
                    </div>
                    <h5>Ινστιτούτο Επιστημών Χημικής Μηχανικής</h5>
                    <p>
                      Το ΙΕΧΜΗ είναι ένα πρωτοπόρο κέντρο παγκόσμιας εμβέλειας για την
                      προώθηση επιστημονικών γνώσεων υψηλής ποιότητας στους τομείς των επιστημών χημικής
                      μηχανική
                    </p>
                    <a href="#iexmh">Περισσότερα</a>
                  </div>
                </div>

                <div className="col-md-4 col-sm-12">
                  <div className={"image-feature " + styles.featured}>
                    <div>
                      <img alt="Feature Image" {...LOGO_GRAFID}/>
                    </div>
                    <h5>Market Research &amp; Analysis</h5>
                    <p>
                      Παραγωγη εξυπνης φαρμακευτικής συσκευασίας με χρηση NFC από γραφένιο.
                    </p>
                    <a href="#graph">Περισσότερα</a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        );
}

export default Featured;