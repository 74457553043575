import React from 'react';


const AbstractGraFID = () =>  {
        return (
          <section className="article-wrapper">
            <div className="container">
              <div className="text-block">
                <div className="detail-line"/>
                <h4 style={{ fontWeight: 900 }}>Το gRaFID</h4>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <div className="article-single">
                    <p>
                      Η ταχύτατη τεχνολογική εξέλιξη της τρίτης, τέταρτης και πέμπτης γενιάς του διαδικτύου, που έχουν σαν
                      βάση τους τη διασύνδεση στον παγκόσμιο ιστό φυσικών αντικειμένων (IoT - Internet of
                      things),προϋποθέτει την ανάπτυξη αισθητήρων που θα μπορούν να ενσωματώνονται σε αντικείμενα στο φυσικό
                      χώρο, προσδίδοντας τους έτσι δυνατότητες αλληλεπίδρασης με το περιβάλλον τους.
                    </p>
                    <p>
                      Έναν τέτοιο ρόλο αναμένεται να παίξουν πολύ σύντομα (ήδη βλέπουμε χρήσεις τους, π.χ. εισιτήρια μετρό,
                      ανέπαφες συναλλαγές κ.α.) και οι ετικέτες RFID (Radio Frequency Identification) - NFC (Near Field
                      Communication) δηλ. στοιχεία αναγνώρισης / σήμανσης στην περιοχή των ραδιοσυχνοτήτων (840-960 ΜΗz /
                      13.56 MHz).
                    </p>
                    <p>
                      Στα πλαίσια του προγράμματος αναπτύσσεται σύστημα εκτύπωσης σε χαρτί εύκαμπτων και ανθεκτικών
                      κυκλωμάτων RFID/NFC, με βάση το μελάνι γραφενίου. Χρησιμοποιείται από το Tipografio.gr η μεταξοτυπία
                      για την εκτύπωση των κυκλωμάτων σε διάφορα υποστρώματα με μελάνια που παράγει το ΙΤΕ/ΙΕΧΜΗ.

                    </p>
                    <p>
                      Οι ετικέτες και τα κυκλώματα εσωτερικά της συσκευασίας σχεδιάζονται έτσι ώστε να είναι σε θέση να
                      παρέχουν πληροφορίες για τον έλεγχο αυθεντικότητας (προσανατολισμένα στα φάρμακα) – που αποτελεί ένα
                      από τα σοβαρότερα προβλήματα της βιομηχανίας (και φαρμακοβιομηχανίας).
                    </p>
                    <p>
                      Στην περίπτωση της βιομηχανίας φαρμάκων στην οποία και είναι προσανατολισμένο το πρόγραμμα σχεδιάζεται
                      και εφαρμογή για κινητά τηλέφωνα, όπου πέρα από τη δυνατότητα αυθεντικοποίησης του φαρμάκου θα μπορεί
                      ο ασθενής να λάβει και επιπλέον πληροφορίες όπως, οι οδηγίες χρήσης και οι παρενέργειες.
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="wide-feature">
                    <h3 style={{ fontWeight: 900 }}>Το όραμα</h3>
                    <ul>
                      <li>Νέες θέσεις εργασίας</li>
                      <li>Πατέντα μηχανισμού αυθεντικοποίησης</li>
                      <li>Ασφάλεια, εγκυρότητα και απλότητα στις συσκευασίες φαρμάκων</li>
                      <li>Αμεσότητα στην πληροφορία</li>
                    </ul>
                  </div>
                  <br/>
                  <div className="wide-feature">
                    <h3 style={{ fontWeight: 900 }}>Γιατί γραφένιο</h3>
                    <ul>
                      <li>Είναι οικονομικό</li>
                      <li>Είναι αγώγιμο</li>
                      <li>Είναι state of the art</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
        );
}

export default AbstractGraFID;